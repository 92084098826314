.flex_x_top {
  display: flex;
  flex: none;
  align-items: flex-start;
}
.flex_x_center {
  display: flex;
  flex: none;
  align-items: safe center;
}
.flex_x_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
}
.flex_x_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
}
.inflex_x_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
}
.inflex_x_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
}
.inflex_x_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
}
.inflex_x_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
}
.flex_center_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: center;
}
.flex_around_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-around;
}
.flex_between_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-between;
}
.flex_left_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex_right_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex_center_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
}
.flex_around_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
}
.flex_between_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
}
.flex_left_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-start;
}
.flex_right_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
}
.flex_center_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
}
.flex_around_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
}
.flex_between_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
}
.flex_left_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-start;
}
.flex_right_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
}
.flex_center_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: center;
}
.flex_around_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-around;
}
.flex_between_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-between;
}
.flex_left_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex_right_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-end;
}
.inflex_center_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: center;
}
.inflex_around_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-around;
}
.inflex_between_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-between;
}
.inflex_left_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-start;
}
.inflex_right_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-end;
}
.inflex_center_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
}
.inflex_around_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
}
.inflex_between_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
}
.inflex_left_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-start;
}
.inflex_right_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
}
.inflex_center_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
}
.inflex_around_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
}
.inflex_between_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
}
.inflex_left_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-start;
}
.inflex_right_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
}
.inflex_center_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: center;
}
.inflex_around_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-around;
}
.inflex_between_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-between;
}
.inflex_left_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-start;
}
.inflex_right_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-end;
}
.splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
.pixelated {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}
.disabletext {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
audioplayer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex: none;
  align-items: stretch;
  flex-direction: column;
  border: 1px solid #000;
  background: #fff;
}
audioplayer icon {
  display: inline-block;
  background: no-repeat center center;
  background-size: auto 100%;
  width: 2em;
  height: 2em;
}
audioplayer icon.mod {
  background-image: url(../images/symb_mod.png);
}
audioplayer icon.mp3 {
  background-image: url(../images/symb_mp3.png);
}
audioplayer icon.ogg {
  background-image: url(../images/symb_ogg.png);
}
audioplayer apmenu {
  display: flex;
  flex: none;
  align-items: stretch;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid #333;
  overflow-y: auto;
}
audioplayer apmenu apmenuin {
  display: flex;
  flex: none;
  align-items: stretch;
  min-height: min-content;
}
audioplayer apmenu aptitre {
  display: flex;
  flex: none;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
}
audioplayer apmenu a {
  display: flex;
  flex: none;
  align-items: stretch;
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #333;
}
audioplayer apmenu a num {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 4em;
  padding: 0.25em 1em;
  border-right: 1px solid #444;
}
audioplayer apmenu a lab {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  flex: 1;
  padding: 0.25em 1em;
}
audioplayer apmenu a status {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 10em;
  padding: 0.25em 1em;
  border-left: 1px solid #444;
  color: #c33;
}
audioplayer applayer {
  display: flex;
  flex: none;
  align-items: stretch;
  flex-direction: column;
  padding: 2.5%;
}
audioplayer applayer apscope {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  height: 10em;
  background: url(../images/audioplayer_back.svg) #303931;
  color: #fff;
}
audioplayer applayer apscope titre {
  display: block;
  padding: 0.5em 1em;
}
audioplayer applayer apscope apstyle {
  font-size: 0.88em;
  opacity: 0.5;
  text-align: right;
  padding: 0.5em 1em;
}
audioplayer applayer apscope apscreen {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-around;
  flex: 1;
  padding: 0 1em;
}
audioplayer applayer apscope apscreen apbar {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 8%;
  height: 100%;
  overflow: hidden;
  padding: 0.1% 0;
}
audioplayer applayer apscope apscreen apbar apminibar {
  display: block;
  height: 8%;
  margin: 2% 0;
  background: rgba(104, 255, 78, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.66);
}
audioplayer applayer apcontrols {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  position: relative;
  margin-top: -1em;
}
audioplayer applayer apcontrols a {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  border: 4px solid #333;
  width: 2.5em;
  height: 2.5em;
  margin: 0 -4px;
  background: #48823F;
  background-image: -webkit-linear-gradient(90deg, #48823F 33%, #fff, #48823F);
  background-image: -moz-linear-gradient(90deg, #48823F 33%, #fff, #48823F);
  background-image: -ms-linear-gradient(90deg, #48823F 33%, #fff, #48823F);
  background-image: -o-linear-gradient(90deg, #48823F 33%, #fff, #48823F);
  background-image: linear-gradient(0deg, #48823F 33%, #fff, #48823F);
}
audioplayer applayer apcontrols a .material-icons {
  color: #333;
  font-size: 2em;
}
audioplayer applayer apcontrols a#playpause {
  width: 4.5em;
  height: 4.5em;
  border-radius: 100%;
  margin: 0 -1em;
  z-index: 5;
  border: 8px solid #333;
}
audioplayer applayer apcontrols a#playpause .material-icons {
  font-size: 3.5em;
}
audioplayer applayer apcontrols a.apbt_prev {
  width: 3.5em;
  padding-right: 1em;
}
audioplayer applayer apcontrols a.apbt_next {
  width: 3.5em;
  padding-left: 1em;
}
audioplayer applayer apcontrols apjog {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  width: 50%;
  height: 1em;
  margin-left: 2em;
  position: relative;
  overflow: visible;
}
audioplayer applayer apcontrols apjog apbarback {
  display: block;
  height: 0.2em;
  width: 100%;
  border: 1px solid #333;
  background: #777;
}
audioplayer applayer apcontrols apjog apbar {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  width: 100%;
  height: 1em;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}
audioplayer applayer apcontrols apjog cursor {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 2px solid #333;
  background: #fff;
  border-radius: 100%;
}
audioplayer applayer apaudiohtml {
  display: none;
}
audioplayer apinfos {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  background: #ccc;
  border-bottom: 8px solid #000;
}
audioplayer apinfos apcomments {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 50%;
  height: 6em;
  font-size: 0.8em;
  padding: 0.5em;
  margin: 0.5em;
  border: 1px solid #999;
  overflow-y: auto;
}
