@import "flex_def.css.less";

audioplayer{ 
    -moz-box-sizing: border-box; box-sizing: border-box; overflow: hidden; 
    .flex_x_stretch();  flex-direction: column; border: 1px solid #000; background: #fff;
    icon{ display: inline-block; background: no-repeat center center; background-size: auto 100%; width: 2em; height: 2em; }
    icon.mod{ background-image: url(../images/symb_mod.png); }
    icon.mp3{ background-image: url(../images/symb_mp3.png); }
    icon.ogg{ background-image: url(../images/symb_ogg.png); }

    apmenu{ 
        .flex_x_stretch(); flex: 1; flex-direction: column; border-right: 1px solid #333; overflow-y: auto;
        apmenuin{ .flex_x_stretch(); min-height: min-content; }
        aptitre{ .flex_x_stretch(); flex-direction: column; width: 100%; }
        a{ 
            .flex_x_stretch(); text-decoration: none; color: #000; border-bottom: 1px solid #333; 
            num{ .inflex_center_center(); width: 4em; padding: 0.25em 1em; border-right:1px solid #444; }
            lab{ .inflex_x_center(); flex: 1; padding: 0.25em 1em; }
            status{ .inflex_center_center(); width: 10em; padding: 0.25em 1em; border-left:1px solid #444; color: #c33; }
        }
    }
        
    applayer{
        .flex_x_stretch();  flex-direction: column; padding: 2.5%;
        apscope{  
            .flex_between_stretch(); flex-direction: column; height: 10em;  
            background: url(../images/audioplayer_back.svg) #303931; color: #fff;
            titre{ display: block;  padding: 0.5em 1em;  }
            apstyle{ font-size: 0.88em; opacity: 0.5; text-align: right;  padding: 0.5em 1em;  }
            apscreen{
                .flex_around_bottom(); flex: 1; padding: 0 1em;
                apbar{
                    .inflex_between_stretch(); flex-direction: column-reverse; width: 8%; height: 100%; overflow: hidden; padding: 0.1% 0;
                    apminibar{ display: block; height: 8%;  margin: 2% 0; background: rgba(104, 255, 78, 0.5); border: 1px solid rgba(255,255,255,0.66); }
                }
            }
        }
        apcontrols{ 
            .flex_center_center(); position: relative; margin-top: -1em;
            a{ 
                .flex_center_center(); border: 4px solid #333; width: 2.5em; height: 2.5em; margin: 0 -4px;
                background: #48823F; .gradient(linear; #48823F 33%, #fff, #48823F);
               .material-icons{ color: #333; font-size: 2em; }
             }
             a#playpause{
                width: 4.5em; height: 4.5em; border-radius: 100%; margin: 0 -1em; z-index: 5; border: 8px solid #333;
                .material-icons{ font-size: 3.5em; }
             }
             a.apbt_prev{ width: 3.5em; padding-right: 1em; }
             a.apbt_next{ width: 3.5em; padding-left: 1em; }
             apjog{ 
                 .inflex_x_center(); width: 50%; height: 1em; margin-left: 2em; position: relative; overflow: visible;
                 apbarback{ display: block; height: 0.2em; width: 100%; border: 1px solid #333; background: #777; }
                 apbar{ .inflex_x_center(); width: 100%; height: 1em; position: absolute; top:0; left: 0; overflow: visible; }
                 cursor{ 
                     display: inline-block; width: 1em; height: 1em; 
                     border: 2px solid #333; background: #fff; border-radius: 100%;
                }
            }
        }
        apaudiohtml{ display: none; }

    }
    apinfos{
        .flex_around_center(); background: #ccc; border-bottom: 8px solid #000;
        apcomments{ .flex_center_center(); width: 50%; height: 6em; font-size: 0.8em;  padding: 0.5em; margin: 0.5em; border: 1px solid #999; overflow-y: auto;  }
    }


}


@media (max-width: 1280px) and (orientation: landscape){ 
    audioplayer{ 

    }
}

@media (max-width: 1280px) and (orientation: portrait){ 
    audioplayer{ 
        
    }
}