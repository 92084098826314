@media (min-width: 1280px){ /*desktop*/
	.mobile{ display: none; }
	header .dummy{ display: none; }

}

@media (max-width: 1280px){ //tout mobile
	.desktop{ display: none; }
	header{
		height: 3.5em;
		logo{ font-size: 0.75em; }
		cell{ padding: 0.5em; 
			i{ color: #000; } 
		}
	}
	menu.mobile{
		padding: 0; margin: 0; height: 100vh;  background: #fff; border-right: 1px solid #000;
		position: fixed; top:0;  z-index: 25;
		in{
			.flex_center_center(); flex-direction: column; height: 100%;
			a{ 
				.inflex_center_center(); text-decoration: none; padding: 0.5em 1em;
				color: #000;
				lab{ display: block; padding: 0.25em 0; border-bottom: 1px solid rgba(0,0,0,0.2); }
			}
		}
	}
	
}

@media (max-width: 1280px) and (orientation: landscape){ 
	.desktop{ display: none; }
	site{ font-size: calc(2vw + 0.56vh); }
	header .dummy{ display: inline-block; flex: 1; }
	
	menu.mobile{  width: 25vw; left: -26vw; }
	
	@if_width: 100vw;

	#me span:last-child { width: 65%; }
	#artwork{
		in pic:nth-child(n+10){ display: none; }
		span{ width: 20%;
			a{ font-size: 2.2vw; }
		}
	}
	.picshow{ 
		pic{ width: 20%; padding-top: 20%; }
	}
	#demoscene nav{ padding: 0; }
	.demolist{ 
		in{ 
			font-size: 1.1em;
			article{ 
				pic{ width: 42vw; height: 31.5vw; } 
			}
		}
	}
	#demos{
		in article:nth-child(n+6){ display: none; }
		span{ width: 42vw;
			a{ font-size: 2.2vw; }
		}
	}
	#audio{ 
		in{ 
			cell{ 
				width: 60%;
				audioplayer{ height: 102vh; }
			}
		} 
	}

	#page_me{ 
		#splash{ height: 60vh; }
		#links{ flex-wrap: wrap; }
	}
}


@media (max-width: 1280px) and (orientation: portrait){ 
	.desktop{ display: none; }
	site{ font-size: calc(3.5vw + 0.56vh); }
	header .dummy{ display: none; }
	
	menu.mobile{  width: 50vw; left: -51vw; }

	@if_width: 100vw;
	#page_home #splash{ 
		flex-direction: column; justify-content: space-between;  padding-top: 3.5em; 
		pic.splashfront{ width: 48vh; height: 38vh; align-self: auto; }
		iframe{ width: @if_width; height: @if_height; }
	}
	
	#me{
		flex-direction: column; align-items: center; 
		span{  width: 92%; }
		span:first-child{ order: 2; justify-content: center; align-self: auto; }
		span:last-child{ order: 1; width: 92%; text-align: center; }
	}
	#artwork{
		in pic:nth-child(n+9){ display: none; }
		span{ width: 33.3%;
			a{ font-size: 3.5vw; }
		}
	}
	.picshow{ 
		pic{ width: 33.3%; padding-top: 33.3%; }
	}
	#demoscene{ 
		nav{ 
			padding: 0; 
			a{ width: 33%; }
		}
	}
	.demolist{ 
		in{ 
			font-size: 1.1em;
			article{ 
				pic{ width: 92vw; height: 69vw; } 
			}
		}
	}
	#demos{
		in article:nth-child(n+4){ display: none; }
		span{ width: 92vw; height: 69vw;
			a{ font-size: 3.5vw; }
		}
	}
	#audio{ 
		in{ 
			flex-direction: column; align-items: center;
			txt{ width: 92%; font-size: 0.88em; text-align: center; }
			cell{ 
				width: 92%;
				audioplayer{ height: 75vh; }
			}
		} 
	}	

	#page_me{ 
		#splash{ height: 33vh; background-size: auto 33.1%; background-position: top right; }
		#links{ flex-wrap: wrap; }
	}
	#page_artwork #splash{
		background-size: auto 58.1%;
		background-position: top center;
	}
	#page_demoscene #splash{
		background-size: auto 58.1%;
		background-position: top center;
	}
	#page_music #splash{
		height: 33vh; background-size: auto 50%; background-position: top center; 
	}	
}

/*
@media (min-device-aspect-ratio: 13/9), (min-aspect-ratio: 13/9){


}
*/


