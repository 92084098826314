*{ -moz-box-sizing: border-box; box-sizing: border-box; overflow: hidden; }
@import "flex_def.css.less";

body{ margin: 0; padding: 0; }
site{ 
	display: block; width: 100vw; height: 100vh; overflow: auto; font-size: calc(1vw + 0.56vh ); font-size: -webkit-calc(1vw + 0.56vh ); 
	position: relative; font-family: 'Titillium Web', sans-serif;
}
span,cell{ display: inline-block; }
h1{ font-weight: normal; margin: 0; padding: 0.5em 1em; font-family: 'Orbitron', sans-serif; }
p{ margin-top: 0; }

pic,icon{ display: inline-block; background: center center no-repeat; background-size: 100% 100%; }
icon.demozoo, pic.demozoo{ background-image: url(../images/me_icons/icon_demozoo.png); }
icon.facebook, pic.facebook{ background-image: url(../images/me_icons/icon_facebook.png); }	
icon.soundcloud, pic.soundcloud{ background-image: url(../images/me_icons/icon_soundcloud.png); }	


pic.ratio,icon.ratio{ background-size: 100% auto; }


input,select,textarea{ font-size: 1em; padding: 0.5em; border: 0.1em solid #000; background: #fff; }

.cta{ .inflex_x_center(); text-decoration: none; font-size: 100%; padding: 0.33em 1em; border-radius: 0.25em; margin: 1em 0; }
.cta_w{ .cta(); color: #000; background: #aaa; border: 1px solid #ccc; .gradient(linear; #aaa 48%, #ccc 48%); text-shadow: 1px 1px 0 #ccc; }
.cta_b{ .cta(); color: #fff; background: #000; border: 1px solid #000; .gradient(linear; #000 48%, #555 48%); text-shadow: -1px -1px 0 #000; }



header{ 
	.flex_between_center(); width: 100vw; height: 6em; position: fixed; top: 0; left: 0; z-index: 50;
	logo{ 
		padding: 1em;
		pic{ width: 18em; height: 3.5em; background-image: url(../images/rlv7_logo.png); }
	}	
	menu{ .inflex_right_center(); align-self: flex-start; margin: 0; }
	menu.desktop{ 
		flex: 1;
		a{ 
			.inflex_center_center(); text-decoration: none; padding: 0.25em 2em; font-size: 0.88em;
			color: #000; font-family: 'Orbitron', sans-serif;
			lab{ display: block; padding: 0.25em 0; border-bottom: 3px solid rgba(0,0,0,0.25); }
		}
		a:last-child{ border-right: none; } 
	}		
}

header.scroll{ 
	background: #fff; font-size: 66%; height: 4em; box-shadow: 0 1vh 1vh rgba(0, 0, 0,0.33); 
	logo{ padding: 0 1em; }
	menu{ align-self: inherit; }
	menu.desktop{
		a{ 
			font-size: 1.1em;
			lab{ border-bottom: none; }
		}	
	}
}

@if_width: 33vw;
@if_height: @if_width * 3/4;

#page_home #splash{ 
	.splash(); background-image: url(../images/rahos_wallpaper_01_back.jpg); height: 100vh;
	animation: defocus 45s both linear infinite;
	left,obj{ .flex_center_center(); flex-direction: column; }
	left cell{ display: none; text-align: center; }
	left cell:first-child{ display: inline-block; }
	pic.splashfront{ width: 54vw; height: 43vw; background-image: url(../images/rahos_wallpaper_01_front.png); align-self: flex-end; }
	iframe{ width: @if_width; height: @if_height; overflow: none; border: none; }
}

@keyframes defocus {
  0% {  background-size: 100% 100%; }
  50% { background-size: 160% 150%; }
  100% { background-size: 100% 100%; } 
}

#me{
	.flex_center_stretch(); padding-top: 1%;
	h1{ background: none; padding: 0.33em 0; }
	span:first-child{ 
		.inflex_right_bottom(); align-self: flex-end;
		pic{ width: 8em; height: 8em; background-image: url(../images/raoid_new.png); }
	}
	span:last-child { width: 40%; padding: 1em; font-size: 0.80em;  }
}

#artwork{ 
	color: #fff; background: url(../images/chalkboard.jpg) no-repeat center center #000; background-size: cover; text-align: center; padding: 2em 0 5em;
	h1{ text-align: center; }


}
.picshow{
	in{ .flex_left_stretch(); flex-wrap: wrap; font-size: 8vw; }
	pic{ width: 10%; padding-top: 10%; background-color: #ccc; background-size: cover; }
	span{ 
		.flex_center_center(); width: 10%;
		background: rgba(255,255,255,0.125);
		a{ font-size: 1vw; }
	}
}

.demozone{
	background: url(../images/demoscene_back.png) no-repeat center center #94B1DB; background-attachment: fixed; background-size: auto 100%;
}

#demoscene{ 
	text-align: center;	
	h1{ text-align: center; }
	p{ font-size: 80%; }
	a{ color: #7f23f8; }
	nav{ 
		.flex_center_stretch(); flex-wrap:wrap; padding: 0 20em;  overflow: visible;
		a{ 
			.inflex_between_center(); width: 20%; flex-direction: column; text-decoration: none; color: #000; 
			font-size: 0.88em; padding: 0.25em; margin:  0.5em 2em; position: relative;  overflow: visible;

			pic{ width: 100%; padding-top: 100%; border-radius: 100%; border: 2px solid #000; }
			actual{ 
				display: inline-block; position: absolute; top: 0; left: 0; color: #ff934f; font-weight: 900; width: 7em; 
				font-size: 0.8em; line-height: 1em; font-family: Arial, Helvetica, sans-serif;
				.outline_deep(#000,1px);
				overflow: visible;
				cell{ transform: rotate(-22deg); padding-bottom: 0.5em; }
			}
			div{ font-family: 'Orbitron', sans-serif; font-weight: 400; padding: 0.5em 0; }
		}
		a:first-child actual cell:before{ content: 'MY ACTUAL GROUP' }
	}
}

@demoWidth: 18vw;
@demoHeight: @demoWidth * 3.25/4;

#demos{	
	padding-bottom: 2em; .gradient(linear; rgba(88, 80, 109,1), rgba(88, 80, 109,0));
	h2{ text-align: center; }
	span{ 
		.flex_center_center(); width: @demoWidth; padding: 0 5% 1%; margin: 1%;
		background: url(../images/noise.png) rgba(0,0,0,1);
		a{ font-size: 1vw; color: #000; }
	}
	//pic{ font-size: 4vw; width: 4em; height: 3em; background-color: #ccc; background-size: cover; }

}
.demolist{
	in{ 
		.flex_center_stretch(); flex-wrap: wrap; font-size: 1vw; 

			article{
				.inflex_between_stretch(); flex-direction: column;
				margin: 1%; 
				/*background: center center no-repeat #777; background-size: auto 100%; .pixelated();*/
				h3{ 
					.flex_between_center(); height: 2.5em; padding: 0 0.5em; margin: 0; font-size: 90%;
					
					//.gradient(linear; rgba(255, 255, 255, 0), rgb(255, 255, 255));
					titre{ 
						.inflex_x_center(); font-family: 'Orbitron', sans-serif;
						//background: url(../images/demo_fond.png) top 0.25em left 0.25em no-repeat; background-size: auto 100%;
					}
					cell{  font-size: 80%; }
				}
				pic{ 
					width: @demoWidth; height: @demoHeight; 
					.nfo{ 
						display: none; height: 100%;
						nfo{
							.flex_between_stretch(); flex-direction: column;  width: 100%; height: 100%; background: rgba(0, 0, 0,0.75); 
							color: #fff; font-size: 0.88em; .disabletext();
							cs{
								.flex_x_stretch(); flex-direction: column; height: 55%;
								comment{ display: block; height: 75%; overflow-y: auto; font-size: 0.88em; padding: 1em; }
								staff{ 
									width: 100%; height: 25%; font-size: 1.35em;  overflow: hidden;
									border-top: 1px solid #444; border-bottom: 1px solid #444; 								
									scroller{ 
										.flex_x_center(); white-space: nowrap; 
										scrl{ .flex_x_center(); /*animation: scroller_anim 5s linear infinite both;*/ white-space: nowrap; }
									}
								}
							}
	
							nav{
								.flex_right_stretch(); flex-wrap: wrap; margin-left: 10em;
								a{ 
									.flex_center_center(); width: 3em; margin: 3%; color: #fff;
									icon{ width: 3em; padding-top: 3em; }
									.material-icons{ font-size: 3em; }				
								}
	
							}
						}
						
					}
				}
				bottom{ 
					.flex_center_center(); height: 2em; padding: 0 0.5em; overflow: visible;
					machine{ 
						display: inline-flex; align-self: flex-end; background: center bottom no-repeat; background-size: 100% auto; margin-bottom: 2%;
					}
					cell{ flex: 1; text-align: center; }
				}

			}
			article.machine_1{
				background-color: #fff;
				h3{ color: #0054AC;  border: 2px solid #0054AC;  }
				pic{ border: 2px solid #0054AC; border-bottom: none; border-top: none; }
				bottom{ 
					color: #0054AC;  border: 2px solid #0054AC; 
					machine{ 
						width: 8em; height: 5em; background-image: url(../images/machines/amiga500.png); background-size: 100% auto;
					}
				}
			}
			article.machine_2,article.machine_3{
				background-color: #6B8EC6;
				h3{ color: #000;  border: 2px outset #000;  }
				pic{ border: 2px outset #000; border-bottom: none; border-top: none; }
				bottom{ 
					color: #000;  border: 2px outset #000;  
					machine{ 
						width: 7em; height: 6em; background-image: url(../images/machines/amiga1200.png); background-size: 100% auto; margin-right: -1.8em;
					}		
				}
			}

			article.machine_4{

				h3{ color: #fff; background: #005AF5; border: 2px solid #005AF5; box-shadow: 0 0.2em 0.5em rgb(176, 237, 255,0.5) inset; border-radius: 0.5em 0.5em 0 0; }
				pic{ border: 2px solid #005AF5; border-bottom: none; border-top: none; }
				bottom{ 
					color: #fff; background: #005AF5; border: 2px solid #005AF5; 
					machine{ 
						width: 4em; height: 5em; background-image: url(../images/machines/pcwin.png); background-size: 100% auto;
					}		
				}
			}

			article.machine_5{
				background-color:#58498A;
				h3{ color: #58498A;  border: 2px solid #120F16; .outline(rgba(0,0,0,0.5),2px); }
				pic{ border: 2px solid #120F16; border-bottom: none; border-top: none; }
				bottom{ 
					color: #58498A; border: 2px solid #120F16; .outline(rgba(0,0,0,0.5),2px); 
					machine{ 
						width: 5em; height: 3.5em; background-image: url(../images/machines/gba.png); background-size: 100% auto;
					}
				}
			}

			article.machine_6{	
				h3{ color: #797e84; border: 2px solid #797e84; .gradient( linear; #e0e1e5 48%, #f7f7f7 48% ); }
				pic{ border: 2px solid #797e84; border-bottom: none; border-top: none; }
				bottom{ 
					color: #3c3f42; border: 2px solid #797e84; .gradient( linear; #767a83, #cacbcf );
					machine{ 
						width: 4.5em; height: 5em; background-image: url(../images/machines/wii.png); background-size: 100% auto;
					}
				}
			}

			article.machine_7{	
				h3{ color: #2E2F31; border: 2px solid #2E2F31; box-shadow: 0 0.25em 1em rgba(0,0,0,0.25) inset; background: #03C0DE; border-radius: 0.5em 0.5em 0 0; }
				pic{ border: 2px solid #2E2F31; border-bottom: none; border-top: none; }
				bottom{ 
					color: #2E2F31; border: 2px solid #2E2F31; box-shadow: 0 -0.25em 1em rgba(0,0,0,0.25) inset; background: #FF5D54; border-radius: 0 0 0.5em 0.5em; 
					machine{ 
						width: 6em; height: 3.75em; background-image: url(../images/machines/switch.png); background-size: 100% auto;
					}
				}
			}
			article.machine_8{
				background-color: #171FC7;
				h3{ color: #fff;  border: 2px solid #171FC7;  }
				pic{ border: 2px solid #171FC7; border-bottom: none; border-top: none; }
				bottom{ 
					color: #fff;  border: 2px solid #171FC7; 
					machine{ 
						width: 6.5em; height: 5.5em; background-image: url(../images/machines/msx1.png); background-size: 100% auto;
					}		
				}
			}
	}		
}

#audio{
	background: url(../images/music_notes.jpg) no-repeat center center #fff; background-size: cover;
	h1{ text-align: center; }
	in{
		.flex_around_center(); padding-bottom: 3vh;
		txt{ 	
			display: inline-block; width: 33%;	font-size: 0.88em;	
			icon{ width: 4em; padding-top: 4em; }
		}
		cell{
			width: 33%;
			audioplayer{ width: 100%; height: 75vh; font-size: 0.75em; } 
		}
	}
}

#picviewer{ 
	display: none; position: fixed; top: 0; left: 0;  background: rgba(0, 0, 0,0.75); z-index: 100;
	in{ 
		.flex_center_center(); width: 100vw; height: 100vh; 
		img{ max-width: 100vw; max-height: 100vh; }
	} 
}


#page_me{
	#splash{ 
		.splash(); background-image: url(../images/me_splash.jpg); height: 75vh;
		gradient{ .gradient(linear; rgba(255,255,255,0), rgba(255,255,255,1)); }
	}
	#links{
		.flex_around_center(); padding: 2em 0;
		a{
			.flex_x_center(); flex-direction: column; text-decoration: none; color: rgba(0,0,0,0.5); font-size: 0.66em;
			pic{ width: 5em; height: 5em; }
		}
	}
}


#page_artwork{
	#splash{ 
		.splash(); background-image: url(../../public/artwork/illustrations/concours_ood_HD.jpg);
		gradient{ .gradient(linear; rgba(255,255,255,0.5), rgba(255,255,255,1)); }
	}
}

#page_demoscene{
	#splash{ 
		.splash(); background-image: url(../../public/demos/rahow_paradise/rahow_paradise.png); .pixelated();
		gradient{ .gradient(linear; rgba(255,255,255,0.25), rgba(255,255,255,1)); }
	}
	.demolist in{
		article{ margin: 1% 0.5%; }
	}
}

#page_greets{
	#splash{ 
		.splash(); height: 33vh; background-image: url(../../public/demos/no_love_v2/nolovev2.jpg); .pixelated();
		gradient{ .gradient(linear; rgba(255,255,255,0.25), rgba(255,255,255,1)); }
	}
	#greetings{ font-size: 0.66em; margin: 2% 0.75%; }
	#greetings.grid{
		
		display: grid;	grid-gap: 1.1em; justify-items: stretch;
		grid-template-columns: repeat(9, 10.2%);

		place-items: stretch;
		article{ 
			border: 1px solid #000; background: 0; 
			h2{ font-size: 1.25em; margin: 0; padding: 0.5em; border-bottom: 1px solid #000; background: #48823F; text-shadow: 1px 1px 0 rgba(255,255,255, 0.25); }
			ul{ 
				border-left: 1px solid #000; margin-left: 1.5em; 
				display: grid;	grid-gap: 1%;
				grid-template-columns: 100%;
				place-items: stretch;
			}
		}
		article.double{
			grid-column: auto / span 2;
			ul{ grid-template-columns: repeat(2, 50%); }
		}		
		article.triple{
			grid-column: auto / span 3;
			ul{ grid-template-columns: repeat(3, 30%); }
		}		
		article.quadruple{
			grid-column: auto / span 4;
			ul{ grid-template-columns: repeat(4, 25%); }
		}
		article.full{
			grid-column: auto / span 9;
			ul{ grid-template-columns: repeat(9, 10.2%); }
		}
	}
}

#page_music{
	#splash{ 
		.splash(); background-image: url(../images/music_back.jpg); height: 40vh;
		gradient{ .gradient(linear; rgba(255,255,255,0.5), rgba(255,255,255,1)); }
	}
}


ct{ display: flex; flex: none; justify-content: center; margin-top: 6em; }

footer{ background: #000; color: #fff; padding: 1em; text-align: center; }


@keyframes scroller_anim{
	0%{ margin-left: 100%; }
	100%{ margin-left: -100%; }
}

@import "style_responsive.css.less";
