* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.flex_x_top {
  display: flex;
  flex: none;
  align-items: flex-start;
}
.flex_x_center {
  display: flex;
  flex: none;
  align-items: safe center;
}
.flex_x_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
}
.flex_x_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
}
.inflex_x_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
}
.inflex_x_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
}
.inflex_x_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
}
.inflex_x_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
}
.flex_center_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: center;
}
.flex_around_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-around;
}
.flex_between_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-between;
}
.flex_left_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex_right_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex_center_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
}
.flex_around_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
}
.flex_between_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
}
.flex_left_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-start;
}
.flex_right_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
}
.flex_center_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
}
.flex_around_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
}
.flex_between_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
}
.flex_left_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-start;
}
.flex_right_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
}
.flex_center_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: center;
}
.flex_around_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-around;
}
.flex_between_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-between;
}
.flex_left_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex_right_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-end;
}
.inflex_center_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: center;
}
.inflex_around_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-around;
}
.inflex_between_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-between;
}
.inflex_left_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-start;
}
.inflex_right_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-end;
}
.inflex_center_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
}
.inflex_around_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
}
.inflex_between_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
}
.inflex_left_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-start;
}
.inflex_right_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
}
.inflex_center_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
}
.inflex_around_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
}
.inflex_between_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
}
.inflex_left_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-start;
}
.inflex_right_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
}
.inflex_center_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: center;
}
.inflex_around_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-around;
}
.inflex_between_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-between;
}
.inflex_left_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-start;
}
.inflex_right_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-end;
}
.splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
.pixelated {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}
.disabletext {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
body {
  margin: 0;
  padding: 0;
}
site {
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  font-size: calc(1vw + 0.56vh);
  font-size: -webkit-calc(1.56vw);
  position: relative;
  font-family: 'Titillium Web', sans-serif;
}
span,
cell {
  display: inline-block;
}
h1 {
  font-weight: normal;
  margin: 0;
  padding: 0.5em 1em;
  font-family: 'Orbitron', sans-serif;
}
p {
  margin-top: 0;
}
pic,
icon {
  display: inline-block;
  background: center center no-repeat;
  background-size: 100% 100%;
}
icon.demozoo,
pic.demozoo {
  background-image: url(../images/me_icons/icon_demozoo.png);
}
icon.facebook,
pic.facebook {
  background-image: url(../images/me_icons/icon_facebook.png);
}
icon.soundcloud,
pic.soundcloud {
  background-image: url(../images/me_icons/icon_soundcloud.png);
}
pic.ratio,
icon.ratio {
  background-size: 100% auto;
}
input,
select,
textarea {
  font-size: 1em;
  padding: 0.5em;
  border: 0.1em solid #000;
  background: #fff;
}
.cta {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  text-decoration: none;
  font-size: 100%;
  padding: 0.33em 1em;
  border-radius: 0.25em;
  margin: 1em 0;
}
.cta_w {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  text-decoration: none;
  font-size: 100%;
  padding: 0.33em 1em;
  border-radius: 0.25em;
  margin: 1em 0;
  color: #000;
  background: #aaa;
  border: 1px solid #ccc;
  background-image: -webkit-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: -moz-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: -ms-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: -o-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: linear-gradient(0deg, #aaa 48%, #ccc 48%);
  text-shadow: 1px 1px 0 #ccc;
}
.cta_b {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  text-decoration: none;
  font-size: 100%;
  padding: 0.33em 1em;
  border-radius: 0.25em;
  margin: 1em 0;
  color: #fff;
  background: #000;
  border: 1px solid #000;
  background-image: -webkit-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: -moz-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: -ms-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: -o-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: linear-gradient(0deg, #000 48%, #555 48%);
  text-shadow: -1px -1px 0 #000;
}
header {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
  width: 100vw;
  height: 6em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}
header logo {
  padding: 1em;
}
header logo pic {
  width: 18em;
  height: 3.5em;
  background-image: url(../images/rlv7_logo.png);
}
header menu {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
  align-self: flex-start;
  margin: 0;
}
header menu.desktop {
  flex: 1;
}
header menu.desktop a {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  text-decoration: none;
  padding: 0.25em 2em;
  font-size: 0.88em;
  color: #000;
  font-family: 'Orbitron', sans-serif;
}
header menu.desktop a lab {
  display: block;
  padding: 0.25em 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.25);
}
header menu.desktop a:last-child {
  border-right: none;
}
header.scroll {
  background: #fff;
  font-size: 66%;
  height: 4em;
  box-shadow: 0 1vh 1vh rgba(0, 0, 0, 0.33);
}
header.scroll logo {
  padding: 0 1em;
}
header.scroll menu {
  align-self: inherit;
}
header.scroll menu.desktop a {
  font-size: 1.1em;
}
header.scroll menu.desktop a lab {
  border-bottom: none;
}
#page_home #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../images/rahos_wallpaper_01_back.jpg);
  height: 100vh;
  animation: defocus 45s both linear infinite;
}
#page_home #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_home #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_home #splash left,
#page_home #splash obj {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  flex-direction: column;
}
#page_home #splash left cell {
  display: none;
  text-align: center;
}
#page_home #splash left cell:first-child {
  display: inline-block;
}
#page_home #splash pic.splashfront {
  width: 54vw;
  height: 43vw;
  background-image: url(../images/rahos_wallpaper_01_front.png);
  align-self: flex-end;
}
#page_home #splash iframe {
  width: 33vw;
  height: 24.75vw;
  overflow: none;
  border: none;
}
@keyframes defocus {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 160% 150%;
  }
  100% {
    background-size: 100% 100%;
  }
}
#me {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
  padding-top: 1%;
}
#me h1 {
  background: none;
  padding: 0.33em 0;
}
#me span:first-child {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
}
#me span:first-child pic {
  width: 8em;
  height: 8em;
  background-image: url(../images/raoid_new.png);
}
#me span:last-child {
  width: 40%;
  padding: 1em;
  font-size: 0.8em;
}
#artwork {
  color: #fff;
  background: url(../images/chalkboard.jpg) no-repeat center center #000;
  background-size: cover;
  text-align: center;
  padding: 2em 0 5em;
}
#artwork h1 {
  text-align: center;
}
.picshow in {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 8vw;
}
.picshow pic {
  width: 10%;
  padding-top: 10%;
  background-color: #ccc;
  background-size: cover;
}
.picshow span {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 10%;
  background: rgba(255, 255, 255, 0.125);
}
.picshow span a {
  font-size: 1vw;
}
.demozone {
  background: url(../images/demoscene_back.png) no-repeat center center #94B1DB;
  background-attachment: fixed;
  background-size: auto 100%;
}
#demoscene {
  text-align: center;
}
#demoscene h1 {
  text-align: center;
}
#demoscene p {
  font-size: 80%;
}
#demoscene a {
  color: #7f23f8;
}
#demoscene nav {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20em;
  overflow: visible;
}
#demoscene nav a {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
  width: 20%;
  flex-direction: column;
  text-decoration: none;
  color: #000;
  font-size: 0.88em;
  padding: 0.25em;
  margin: 0.5em 2em;
  position: relative;
  overflow: visible;
}
#demoscene nav a pic {
  width: 100%;
  padding-top: 100%;
  border-radius: 100%;
  border: 2px solid #000;
}
#demoscene nav a actual {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: #ff934f;
  font-weight: 900;
  width: 7em;
  font-size: 0.8em;
  line-height: 1em;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 0 1px 0 #000, 1px 0 0 #000, 0 -1px 0 #000, -1px 0 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, 2px 2px 0 #000, 3px 3px 0 #000, 4px 4px 0 #000, 5px 5px 0 #000, 6px 6px 0 #000;
  overflow: visible;
}
#demoscene nav a actual cell {
  transform: rotate(-22deg);
  padding-bottom: 0.5em;
}
#demoscene nav a div {
  font-family: 'Orbitron', sans-serif;
  font-weight: 400;
  padding: 0.5em 0;
}
#demoscene nav a:first-child actual cell:before {
  content: 'MY ACTUAL GROUP';
}
#demos {
  padding-bottom: 2em;
  background-image: -webkit-linear-gradient(90deg, #58506d, rgba(88, 80, 109, 0));
  background-image: -moz-linear-gradient(90deg, #58506d, rgba(88, 80, 109, 0));
  background-image: -ms-linear-gradient(90deg, #58506d, rgba(88, 80, 109, 0));
  background-image: -o-linear-gradient(90deg, #58506d, rgba(88, 80, 109, 0));
  background-image: linear-gradient(0deg, #58506d, rgba(88, 80, 109, 0));
}
#demos h2 {
  text-align: center;
}
#demos span {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 18vw;
  padding: 0 5% 1%;
  margin: 1%;
  background: url(../images/noise.png) #000000;
}
#demos span a {
  font-size: 1vw;
  color: #000;
}
.demolist in {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1vw;
}
.demolist in article {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  margin: 1%;
  /*background: center center no-repeat #777; background-size: auto 100%; .pixelated();*/
}
.demolist in article h3 {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
  height: 2.5em;
  padding: 0 0.5em;
  margin: 0;
  font-size: 90%;
}
.demolist in article h3 titre {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  font-family: 'Orbitron', sans-serif;
}
.demolist in article h3 cell {
  font-size: 80%;
}
.demolist in article pic {
  width: 18vw;
  height: 14.625vw;
}
.demolist in article pic .nfo {
  display: none;
  height: 100%;
}
.demolist in article pic .nfo nfo {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 0.88em;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
.demolist in article pic .nfo nfo cs {
  display: flex;
  flex: none;
  align-items: stretch;
  flex-direction: column;
  height: 55%;
}
.demolist in article pic .nfo nfo cs comment {
  display: block;
  height: 75%;
  overflow-y: auto;
  font-size: 0.88em;
  padding: 1em;
}
.demolist in article pic .nfo nfo cs staff {
  width: 100%;
  height: 25%;
  font-size: 1.35em;
  overflow: hidden;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
}
.demolist in article pic .nfo nfo cs staff scroller {
  display: flex;
  flex: none;
  align-items: safe center;
  white-space: nowrap;
}
.demolist in article pic .nfo nfo cs staff scroller scrl {
  display: flex;
  flex: none;
  align-items: safe center;
  /*animation: scroller_anim 5s linear infinite both;*/
  white-space: nowrap;
}
.demolist in article pic .nfo nfo nav {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-left: 10em;
}
.demolist in article pic .nfo nfo nav a {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 3em;
  margin: 3%;
  color: #fff;
}
.demolist in article pic .nfo nfo nav a icon {
  width: 3em;
  padding-top: 3em;
}
.demolist in article pic .nfo nfo nav a .material-icons {
  font-size: 3em;
}
.demolist in article bottom {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  height: 2em;
  padding: 0 0.5em;
  overflow: visible;
}
.demolist in article bottom machine {
  display: inline-flex;
  align-self: flex-end;
  background: center bottom no-repeat;
  background-size: 100% auto;
  margin-bottom: 2%;
}
.demolist in article bottom cell {
  flex: 1;
  text-align: center;
}
.demolist in article.machine_1 {
  background-color: #fff;
}
.demolist in article.machine_1 h3 {
  color: #0054AC;
  border: 2px solid #0054AC;
}
.demolist in article.machine_1 pic {
  border: 2px solid #0054AC;
  border-bottom: none;
  border-top: none;
}
.demolist in article.machine_1 bottom {
  color: #0054AC;
  border: 2px solid #0054AC;
}
.demolist in article.machine_1 bottom machine {
  width: 8em;
  height: 5em;
  background-image: url(../images/machines/amiga500.png);
  background-size: 100% auto;
}
.demolist in article.machine_2,
.demolist in article.machine_3 {
  background-color: #6B8EC6;
}
.demolist in article.machine_2 h3,
.demolist in article.machine_3 h3 {
  color: #000;
  border: 2px outset #000;
}
.demolist in article.machine_2 pic,
.demolist in article.machine_3 pic {
  border: 2px outset #000;
  border-bottom: none;
  border-top: none;
}
.demolist in article.machine_2 bottom,
.demolist in article.machine_3 bottom {
  color: #000;
  border: 2px outset #000;
}
.demolist in article.machine_2 bottom machine,
.demolist in article.machine_3 bottom machine {
  width: 7em;
  height: 6em;
  background-image: url(../images/machines/amiga1200.png);
  background-size: 100% auto;
  margin-right: -1.8em;
}
.demolist in article.machine_4 h3 {
  color: #fff;
  background: #005AF5;
  border: 2px solid #005AF5;
  box-shadow: 0 0.2em 0.5em #b0edff inset;
  border-radius: 0.5em 0.5em 0 0;
}
.demolist in article.machine_4 pic {
  border: 2px solid #005AF5;
  border-bottom: none;
  border-top: none;
}
.demolist in article.machine_4 bottom {
  color: #fff;
  background: #005AF5;
  border: 2px solid #005AF5;
}
.demolist in article.machine_4 bottom machine {
  width: 4em;
  height: 5em;
  background-image: url(../images/machines/pcwin.png);
  background-size: 100% auto;
}
.demolist in article.machine_5 {
  background-color: #58498A;
}
.demolist in article.machine_5 h3 {
  color: #58498A;
  border: 2px solid #120F16;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5), 2px 0 0 rgba(0, 0, 0, 0.5), 0 -2px 0 rgba(0, 0, 0, 0.5), -2px 0 0 rgba(0, 0, 0, 0.5), -2px -2px 0 rgba(0, 0, 0, 0.5), 2px -2px 0 rgba(0, 0, 0, 0.5), 2px 2px 0 rgba(0, 0, 0, 0.5), -2px 2px 0 rgba(0, 0, 0, 0.5);
}
.demolist in article.machine_5 pic {
  border: 2px solid #120F16;
  border-bottom: none;
  border-top: none;
}
.demolist in article.machine_5 bottom {
  color: #58498A;
  border: 2px solid #120F16;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5), 2px 0 0 rgba(0, 0, 0, 0.5), 0 -2px 0 rgba(0, 0, 0, 0.5), -2px 0 0 rgba(0, 0, 0, 0.5), -2px -2px 0 rgba(0, 0, 0, 0.5), 2px -2px 0 rgba(0, 0, 0, 0.5), 2px 2px 0 rgba(0, 0, 0, 0.5), -2px 2px 0 rgba(0, 0, 0, 0.5);
}
.demolist in article.machine_5 bottom machine {
  width: 5em;
  height: 3.5em;
  background-image: url(../images/machines/gba.png);
  background-size: 100% auto;
}
.demolist in article.machine_6 h3 {
  color: #797e84;
  border: 2px solid #797e84;
  background-image: -webkit-linear-gradient(90deg, #e0e1e5 48%, #f7f7f7 48%);
  background-image: -moz-linear-gradient(90deg, #e0e1e5 48%, #f7f7f7 48%);
  background-image: -ms-linear-gradient(90deg, #e0e1e5 48%, #f7f7f7 48%);
  background-image: -o-linear-gradient(90deg, #e0e1e5 48%, #f7f7f7 48%);
  background-image: linear-gradient(0deg, #e0e1e5 48%, #f7f7f7 48%);
}
.demolist in article.machine_6 pic {
  border: 2px solid #797e84;
  border-bottom: none;
  border-top: none;
}
.demolist in article.machine_6 bottom {
  color: #3c3f42;
  border: 2px solid #797e84;
  background-image: -webkit-linear-gradient(90deg, #767a83, #cacbcf);
  background-image: -moz-linear-gradient(90deg, #767a83, #cacbcf);
  background-image: -ms-linear-gradient(90deg, #767a83, #cacbcf);
  background-image: -o-linear-gradient(90deg, #767a83, #cacbcf);
  background-image: linear-gradient(0deg, #767a83, #cacbcf);
}
.demolist in article.machine_6 bottom machine {
  width: 4.5em;
  height: 5em;
  background-image: url(../images/machines/wii.png);
  background-size: 100% auto;
}
.demolist in article.machine_7 h3 {
  color: #2E2F31;
  border: 2px solid #2E2F31;
  box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.25) inset;
  background: #03C0DE;
  border-radius: 0.5em 0.5em 0 0;
}
.demolist in article.machine_7 pic {
  border: 2px solid #2E2F31;
  border-bottom: none;
  border-top: none;
}
.demolist in article.machine_7 bottom {
  color: #2E2F31;
  border: 2px solid #2E2F31;
  box-shadow: 0 -0.25em 1em rgba(0, 0, 0, 0.25) inset;
  background: #FF5D54;
  border-radius: 0 0 0.5em 0.5em;
}
.demolist in article.machine_7 bottom machine {
  width: 6em;
  height: 3.75em;
  background-image: url(../images/machines/switch.png);
  background-size: 100% auto;
}
.demolist in article.machine_8 {
  background-color: #171FC7;
}
.demolist in article.machine_8 h3 {
  color: #fff;
  border: 2px solid #171FC7;
}
.demolist in article.machine_8 pic {
  border: 2px solid #171FC7;
  border-bottom: none;
  border-top: none;
}
.demolist in article.machine_8 bottom {
  color: #fff;
  border: 2px solid #171FC7;
}
.demolist in article.machine_8 bottom machine {
  width: 6.5em;
  height: 5.5em;
  background-image: url(../images/machines/msx1.png);
  background-size: 100% auto;
}
#audio {
  background: url(../images/music_notes.jpg) no-repeat center center #fff;
  background-size: cover;
}
#audio h1 {
  text-align: center;
}
#audio in {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  padding-bottom: 3vh;
}
#audio in txt {
  display: inline-block;
  width: 33%;
  font-size: 0.88em;
}
#audio in txt icon {
  width: 4em;
  padding-top: 4em;
}
#audio in cell {
  width: 33%;
}
#audio in cell audioplayer {
  width: 100%;
  height: 75vh;
  font-size: 0.75em;
}
#picviewer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
}
#picviewer in {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
#picviewer in img {
  max-width: 100vw;
  max-height: 100vh;
}
#page_me #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../images/me_splash.jpg);
  height: 75vh;
}
#page_me #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_me #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_me #splash gradient {
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
  background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0), #ffffff);
}
#page_me #links {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  padding: 2em 0;
}
#page_me #links a {
  display: flex;
  flex: none;
  align-items: safe center;
  flex-direction: column;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.66em;
}
#page_me #links a pic {
  width: 5em;
  height: 5em;
}
#page_artwork #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../../public/artwork/illustrations/concours_ood_HD.jpg);
}
#page_artwork #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_artwork #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_artwork #splash gradient {
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), #ffffff);
}
#page_demoscene #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../../public/demos/rahow_paradise/rahow_paradise.png);
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}
#page_demoscene #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_demoscene #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_demoscene #splash gradient {
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.25), #ffffff);
}
#page_demoscene .demolist in article {
  margin: 1% 0.5%;
}
#page_greets #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 33vh;
  background-image: url(../../public/demos/no_love_v2/nolovev2.jpg);
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}
#page_greets #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_greets #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_greets #splash gradient {
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0.25), #ffffff);
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.25), #ffffff);
}
#page_greets #greetings {
  font-size: 0.66em;
  margin: 2% 0.75%;
}
#page_greets #greetings.grid {
  display: grid;
  grid-gap: 1.1em;
  justify-items: stretch;
  grid-template-columns: repeat(9, 10.2%);
  place-items: stretch;
}
#page_greets #greetings.grid article {
  border: 1px solid #000;
  background: 0;
}
#page_greets #greetings.grid article h2 {
  font-size: 1.25em;
  margin: 0;
  padding: 0.5em;
  border-bottom: 1px solid #000;
  background: #48823F;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.25);
}
#page_greets #greetings.grid article ul {
  border-left: 1px solid #000;
  margin-left: 1.5em;
  display: grid;
  grid-gap: 1%;
  grid-template-columns: 100%;
  place-items: stretch;
}
#page_greets #greetings.grid article.double {
  grid-column: auto / span 2;
}
#page_greets #greetings.grid article.double ul {
  grid-template-columns: repeat(2, 50%);
}
#page_greets #greetings.grid article.triple {
  grid-column: auto / span 3;
}
#page_greets #greetings.grid article.triple ul {
  grid-template-columns: repeat(3, 30%);
}
#page_greets #greetings.grid article.quadruple {
  grid-column: auto / span 4;
}
#page_greets #greetings.grid article.quadruple ul {
  grid-template-columns: repeat(4, 25%);
}
#page_greets #greetings.grid article.full {
  grid-column: auto / span 9;
}
#page_greets #greetings.grid article.full ul {
  grid-template-columns: repeat(9, 10.2%);
}
#page_music #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../images/music_back.jpg);
  height: 40vh;
}
#page_music #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_music #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_music #splash gradient {
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0.5), #ffffff);
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), #ffffff);
}
ct {
  display: flex;
  flex: none;
  justify-content: center;
  margin-top: 6em;
}
footer {
  background: #000;
  color: #fff;
  padding: 1em;
  text-align: center;
}
@keyframes scroller_anim {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -100%;
  }
}
@media (min-width: 1280px) {
  /*desktop*/
  .mobile {
    display: none;
  }
  header .dummy {
    display: none;
  }
}
@media (max-width: 1280px) {
  .desktop {
    display: none;
  }
  header {
    height: 3.5em;
  }
  header logo {
    font-size: 0.75em;
  }
  header cell {
    padding: 0.5em;
  }
  header cell i {
    color: #000;
  }
  menu.mobile {
    padding: 0;
    margin: 0;
    height: 100vh;
    background: #fff;
    border-right: 1px solid #000;
    position: fixed;
    top: 0;
    z-index: 25;
  }
  menu.mobile in {
    display: flex;
    flex: none;
    align-items: safe center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  menu.mobile in a {
    display: inline-flex;
    flex: none;
    align-items: safe center;
    justify-content: center;
    text-decoration: none;
    padding: 0.5em 1em;
    color: #000;
  }
  menu.mobile in a lab {
    display: block;
    padding: 0.25em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 1280px) and (orientation: landscape) {
  .desktop {
    display: none;
  }
  site {
    font-size: calc(2vw + 0.56vh);
  }
  header .dummy {
    display: inline-block;
    flex: 1;
  }
  menu.mobile {
    width: 25vw;
    left: -26vw;
  }
  #me span:last-child {
    width: 65%;
  }
  #artwork in pic:nth-child(n+10) {
    display: none;
  }
  #artwork span {
    width: 20%;
  }
  #artwork span a {
    font-size: 2.2vw;
  }
  .picshow pic {
    width: 20%;
    padding-top: 20%;
  }
  #demoscene nav {
    padding: 0;
  }
  .demolist in {
    font-size: 1.1em;
  }
  .demolist in article pic {
    width: 42vw;
    height: 31.5vw;
  }
  #demos in article:nth-child(n+6) {
    display: none;
  }
  #demos span {
    width: 42vw;
  }
  #demos span a {
    font-size: 2.2vw;
  }
  #audio in cell {
    width: 60%;
  }
  #audio in cell audioplayer {
    height: 102vh;
  }
  #page_me #splash {
    height: 60vh;
  }
  #page_me #links {
    flex-wrap: wrap;
  }
}
@media (max-width: 1280px) and (orientation: portrait) {
  .desktop {
    display: none;
  }
  site {
    font-size: calc(3.5vw + 0.56vh);
  }
  header .dummy {
    display: none;
  }
  menu.mobile {
    width: 50vw;
    left: -51vw;
  }
  #page_home #splash {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 3.5em;
  }
  #page_home #splash pic.splashfront {
    width: 48vh;
    height: 38vh;
    align-self: auto;
  }
  #page_home #splash iframe {
    width: 100vw;
    height: 75vw;
  }
  #me {
    flex-direction: column;
    align-items: center;
  }
  #me span {
    width: 92%;
  }
  #me span:first-child {
    order: 2;
    justify-content: center;
    align-self: auto;
  }
  #me span:last-child {
    order: 1;
    width: 92%;
    text-align: center;
  }
  #artwork in pic:nth-child(n+9) {
    display: none;
  }
  #artwork span {
    width: 33.3%;
  }
  #artwork span a {
    font-size: 3.5vw;
  }
  .picshow pic {
    width: 33.3%;
    padding-top: 33.3%;
  }
  #demoscene nav {
    padding: 0;
  }
  #demoscene nav a {
    width: 33%;
  }
  .demolist in {
    font-size: 1.1em;
  }
  .demolist in article pic {
    width: 92vw;
    height: 69vw;
  }
  #demos in article:nth-child(n+4) {
    display: none;
  }
  #demos span {
    width: 92vw;
    height: 69vw;
  }
  #demos span a {
    font-size: 3.5vw;
  }
  #audio in {
    flex-direction: column;
    align-items: center;
  }
  #audio in txt {
    width: 92%;
    font-size: 0.88em;
    text-align: center;
  }
  #audio in cell {
    width: 92%;
  }
  #audio in cell audioplayer {
    height: 75vh;
  }
  #page_me #splash {
    height: 33vh;
    background-size: auto 33.1%;
    background-position: top right;
  }
  #page_me #links {
    flex-wrap: wrap;
  }
  #page_artwork #splash {
    background-size: auto 58.1%;
    background-position: top center;
  }
  #page_demoscene #splash {
    background-size: auto 58.1%;
    background-position: top center;
  }
  #page_music #splash {
    height: 33vh;
    background-size: auto 50%;
    background-position: top center;
  }
}
/*
@media (min-device-aspect-ratio: 13/9), (min-aspect-ratio: 13/9){


}
*/
